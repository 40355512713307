import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './assets/Header.css';
import fire from './assets/images/202.gif';
import plus from './assets/images/lockup.gif';

ReactGA.initialize('UA-134506528-1', {
  debug: false,
  siteSpeedSampleRate: 100,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

class Header extends Component {

  beginTriggers = () => {
    console.log('beginTrigger', new Date());
    ReactGA.pageview('Triggers');
  };

  gAClick = (b) => {
    ReactGA.event({
      category: 'External Website',
      action: b,
    });
  }

  businessTime = () => {
    this.gAClick('Business Time');
  };

	polaroidsLink = () => {
    this.gAClick('polariods');
  };

	walkmanLink = () => {
    this.gAClick('walkman');
  };

  textLinkToWebsite = () => {
    this.gAClick('Top banner to m+a website');
    window.open('https://www.mna.co');
  };
  
  handleLinked = (e) => {
    e.preventDefault();
    window.open('https://www.linkedin.com/company/minds-assembly/');
    this.businessTime();
  };

  handleInsta = (e) => {
    e.preventDefault();
    window.open('https://www.instagram.com/minds_assembly/');
    this.polaroidsLink();
  };

  handleSpotify = (e) => {
    e.preventDefault();
    window.open('https://open.spotify.com/playlist/4NPYnFL3EQcHPB82Qbv5A5');
    this.walkmanLink();
  };

  render() {
    return (
      <div>
        <div className='logo'>
          <img src={plus} alt='lockup' width='350' />
          <marquee>
            Brought to you by minds + assembly
          </marquee>
        </div>  
        <div className='fuego'>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
          <img src={fire} width='100px' alt='fire'/>
        </div>

        <center>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button className='button' onClick={this.handleLinked} onTouchEnd={this.handleLinked}>
            Business Time
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button className='button' onClick={this.handleInsta} onTouchEnd={this.handleInsta}>
            Polaroids
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button className='button' onClick={this.handleSpotify} onTouchEnd={this.handleSpotify}>
            Walkman
          </button>
        </center>
        <div className='intro'>
          <p>
            Do you have a mixtape, but no one to share it with?<br />
            A song that resonates with your heart, but nobody to dedicate it to on the radio?<br /><br />
            <span className='spacing'>Welcome to Connec+ions—</span><br />
            the foolproof way to find your match, brought 
            to you by <span onClick={this.textLinkToWebsite} onTouchEnd={this.textLinkToWebsite} className='bloo'>minds + assembly</span>.
            </p>
        </div>
      </div>
    );
  }
}
  
export default Header;