import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './assets/ProfileCard.css';
import email from './assets/images/say_hello2.png';
import heart from './assets/images/heart.gif';

class ProfileCard extends Component {
  launchVideo = () => {
    console.log('launching video');
    let cont = document.createElement('div');
    let vidCont = document.createElement('div');
    let video = document.createElement('video');
    let close = document.createElement('span');
    let play = document.createElement('span');
    let pause = document.createElement('span');

    cont.classList.add('video-container-holder');
    vidCont.classList.add('video-container');
    play.classList.add('vid-buttons');
    pause.classList.add('vid-buttons');
    video.src = this.props.video;
    video.playsinline = true;
    video.classList.add('video');
    close.classList.add('close');
    close.innerHTML = 'x close';
    play.innerHTML = '&#9658;';
    pause.innerHTML = '&#9612;&#9612;';

    play.addEventListener('click', () => video.play() );
    pause.addEventListener('click', () => video.pause() );
    close.addEventListener('click', () => {
      this.removeObject(cont);
      this.closeVideo();
    });
    play.addEventListener('touchstart', () => video.play() );
    pause.addEventListener('touchstart', () => video.pause() );
    close.addEventListener('touchstart', () => {
      this.removeObject(cont);
      this.closeVideo();
    });
    
    vidCont.appendChild(video);
    vidCont.appendChild(play);
    vidCont.appendChild(pause);
    vidCont.appendChild(close);
    cont.appendChild(vidCont);
    document.body.appendChild(cont);
    video.play();

    this.openVideo();
  };

  removeObject = (o) => {
    o.remove();
  };

  gAClick = (b) => {
    ReactGA.event({
      category: 'User Interactions',
      action: b,
    });
  };

  openVideo = () => {
    this.gAClick(`Opened ${this.props.name}'s video`);
  };

  closeVideo = () => {
    this.gAClick(`Closed ${this.props.name}'s video`);
  };

  clickedEmail = () => {
    this.gAClick(`Clicked to email ${this.props.name}`);
  };

  render() {
    return (
      <div key={this.props.id} className="ProfileCard" >
        <div className='tester'>
          <div className="profile-image">
            <img className='actual-profile-image' src={this.props.image} alt={`portrait of ${this.props.name}`} onClick={this.launchVideo} onTouchEnd={this.launchVideo}/>
            <a href={this.props.emailLink} rel="EMAIL">
              <img className='email' onClick={this.clickedEmail} onTouchEnd={this.clickedEmail} src={email} alt='email' />
            </a>
          </div>
          <div className="description">
            <h4>{this.props.name}</h4>
            <p>{this.props.bio}</p>
            <button className='profile-button' onClick={this.launchVideo} onTouchEnd={this.launchVideo}>
              Watch Profile Video
            </button>
          </div>
        </div>
        <img className='heart-gif' src={heart} alt='heart' />
      </div>
    );
  };
};

export default ProfileCard;