import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ProfileCard from './ProfileCard';
import profileData from './profileData.js';
import './Profiles.css';
import './assets/Profiles.css';
import lips from './assets/images/lips.gif';
import newsflash from './assets/images/newsflash.gif';


class Profiles extends Component {

	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		// this.openModal = this.openModal.bind(this)
  }
  
  gAClick = (b) => {
		ReactGA.event({
		  category: 'External Website',
		  action: b,
    });
  };

  bannerToWebsite = () => {
    this.gAClick('Top banner to m+a website');
    window.open('https://www.mna.co');
  };

	openModal = () => {
		this.setState({ isOpen: true })
	}

	closeModal = () => {
		this.setState({isOpen: false})
	}

  render() {
		// Pull in json data from profileData.js file
		const profiles = profileData;	
		
		//Load a ProfileCard for each item, pass the data as a prop to it
    return (
			<div>
				<center className='meet-intro'>
          <h1>Meet your Match</h1>
          <p>Watch the video profiles below to see who catches your eye, and maybe your heart.</p>
				</center>

        <div className='newsflash'>
          <img src={lips} width='30px' alt='lips'/>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img onClick={this.bannerToWebsite} onTouchEnd={this.bannerToWebsite} src={newsflash} alt='minds + assembly newsflash' className='bannerexit'/>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img src={lips} width='30px' alt='lips'/>
        </div>

				<div className="flexbox">
					{profiles.map(profile =>
						<ProfileCard
							key={profile.id}
							name={profile.name}
							bio={profile.bio}
							image={profile.image}
              emailLink={profile.emailLink}
							onClose={this.closeModal}
							openModal={this.openModal}
              state={this.state.isOpen}
              video={profile.video}
						/>
					)}
				</div>
			</div>
    );
  };
};

export default Profiles;