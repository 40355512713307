const profileData = [
	{
		"id": 1,
		"name": "Becky",
		"bio": "Looking for love in Alderaan places",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/becky.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/becky.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Becky&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 2,
		"name": "Mitch",
		"bio": "This mustache is 50% sweat and 50% cashmere.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/mitch.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/mitch.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Mitch&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 3,
		"name": "Cory",
		"bio": "Let’s party. VIP, of course.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/cory.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/cory.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Cory&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 4,
		"name": "Bo",
		"bio": "Can you handle a piece of this Pisces?",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/bo.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/bo.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Bo&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 5,
		"name": "Phuong",
		"bio": "My biggest weakness is not having a weakness at all.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/phuong.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/phuong.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Phuong&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 6,
		"name": "Jamie",
		"bio": "If you’ve got the wine, I’ve got the time.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/jamie.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/jamie.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Jamie&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 7,
		"name": "Kelsey",
		"bio": "Must love dogs...and tiny hands.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/kelsey.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/kelsey.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Kelsey&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 8,
		"name": "Nico",
		"bio": "Magic the Gathering champ who doesn’t know when to quit.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/nico2.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/nico.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Nico&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 9,
		"name": "Davey",
		"bio": "Looking for someone who knows the value of a subway token.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/davey.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/davey.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Dave&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 10,
		"name": "Catherine",
		"bio": "Looking for the Strunk to her White. ",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/catherine.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/catherine.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Catherine&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 11,
		"name": "Carol",
		"bio": "Hides her feelings behind chopped onions",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/carol.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/carol.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Carol&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 12,
		"name": "Taylor",
		"bio": "Jock by day. Meathead by night.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/taylor.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/taylor.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Taylor&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."	
	},
	{
		"id": 13,
		"name": "Megan",
		"bio": "Leos need not apply.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/megan.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/megan.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Megan&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 14,
		"name": "Stacey",
		"bio": "It’s 9:09 am. Stacey knows where you are. Do you?",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/stacy.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/stacy.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Stacey&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 15,
		"name": "Jeremiah",
		"bio": "The “h” is NOT silent.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/jeremiah.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/jeremiah.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Jeremiah&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 16,
		"name": "Arturo",
		"bio": "“El Matador”",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/arturo.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/arturo.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Arturo&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."
	},
	{
		"id": 17,
		"name": "Debbie",
		"bio": " It's a numbers game.",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/debbie.jpg", 
		"video": "#",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Debbie&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."	
	},
	{
		"id": 18,
		"name": "Brie",
		"bio": "Milk before cereal, never been more real",
		"image": "https://s3.amazonaws.com/cdn.mna/connections/images/brie.jpg", 
		"video": "https://s3.amazonaws.com/cdn.mna/connections/videos/brie.mp4",
		"emailLink": "mailto:connections@mna.co?subject=I%E2%80%99m%20inspired%20to%20connect%20with%20Brie&body=I%20want%20to%20connec%2B%20with%20minds%20%2B%20assembly%20because%20I%20need%20to%E2%80%A6%0A%0A1)%20Work%20with%20an%20agency%20that%20puts%20this%20much%20heart%20into%20everything%20they%20do.%0A%0A2)%20Be%20a%20part%20of%20an%20agency%20that%20created%20a%2090s-inspired%20matchmaking%20site%2C%20just%20for%20fun.%0A%0A3)%20Just%20tell%20you%20that%20was%20awesome."	
	}
]

export default profileData;