import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './assets/Footer.css';
import internet from './assets/images/internet.gif';
import ads from './assets/images/wemakeads.gif';
import lips from './assets/images/lips.gif';

class Footer extends Component {
  copyLink = (e) => {
    e.stopPropagation();
    
    // Create temporary text area
    const textArea = document.createElement('textarea');
    textArea.style.fontSize = '16px';
    textArea.readOnly = true;
		textArea.value = "https://connections.mna.co";
    document.body.appendChild(textArea);
    
    // Set window focus
    textArea.focus();
    // Highlight text
    textArea.setSelectionRange(0, textArea.value.length);
    // Copy contents to clipboard
    document.execCommand('copy');
    // Remove text area
    document.body.removeChild(textArea);
    // Indicate link has been copied
    //document.getElementById('button').innerHTML = 'COPIED!';
  };

  gAClick = (b) => {
    ReactGA.event({
      category: 'External Website',
      action: b,
    });
  };

  bannerClick = () => {
    this.gAClick('Linked to m+a homepage from bottom banner');
    window.open('https://www.mna.co');
  };

  render() {
    return (
      <div className='footer'>
        <div className='dotted'></div>
        <div className='internet'>
          <h4>Tell your friends!</h4>
          <img src={internet} alt='I saw it on the internet' onClick={this.copyLink} onTouchEnd={this.copyLink} />
        </div>
        <div className='ad'>
        <img src={lips} width='30px' alt='kiss' />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img src={ads} alt='we make ads' onClick={this.bannerClick} onTouchEnd={this.bannerClick} className='footer-banner' />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img src={lips} width='30px' alt='kiss' />
        </div>
        <div className='counter'>
          <h4>You are visitor number:</h4>
            <img src='https://www.counter12.com/img-D6DWWZza2Z2wc5AW-1.gif' border='0' alt='conter12'/>
          <script type='text/javascript' src='https://www.counter12.com/ad.js?id=D6DWWZza2Z2wc5AW'></script>
        </div>
      </div>
    );
  }
}
  
export default Footer;